.video-editor-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255);
  width: calc(40% - 40px);
  min-width: 280px;
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  //height: 90vh;
  //overflow: scroll;
  .video-editor-components {
    width: 80%;
    min-width: 220px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .video-editor-components-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-width: 220px;
      height: 40px;

      span {
        font-size: 14px;
        font-weight: bold;
        color: #222222;
      }
    }

    .video-editor-functions {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      min-width: 220px;
      padding-bottom: 20px;
      position: relative;

      .video-editor-layers-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        min-width: 150px;
        min-height: 75px;
        position: relative;
        padding-bottom: 75px;
        padding-top: 13px;
        .video-editing-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 75px;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          box-sizing: border-box;
          //background-color: #ed0202;
        }

        input {
          width: 100%;
          height: 75px;
          padding: 10px;
          border: 1px, solid #cbcbcb;
          border-radius: 10px;
          position: absolute;
          bottom: 0;
          font-size: 14px;
          font-weight: bold;
          color: #666666;
        }
        input::placeholder {
          color: #666666;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
  .audio-editor-components {
    width: 20%;
    height: 100%;
    background-color: #efefef;

    .hide-info-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      height: 53px;
      width: 100%;
      border: none;

      &:hover {
        cursor: pointer;
        background-color: #c2bdbd;
      }

      .back-arrow-icon {
        width: 15px;
        height: 15px;
      }
    }
    .audio-editor-layers-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 0 5px;
      .audio-editor-layer {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 740px;
        width: 40%;
        border-right: 1px dashed #c3c3c3;
      }
    }
  }
}
