.playback-marker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: -3px;
  left: -20px;
  width: 130%;
  height: 30px;
  z-index: 2;

  .triangle-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
  }
}
