.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 10%;
  min-width: 50px;
  max-width: 100px;

  .timeline-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #999999;
    font-family: 'Space Grotesk', sans-serif;

    &.bold {
      font-weight: bold;
      color: #666666;
    }

    .timeline-seconds {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      height: 35px;
      .dot {
        width: 2px;
        height: 2px;
        background-color: #edeaea;
      }
    }
    span {
      font-size: 11px;
      line-height: 12px;
    }
  }
}
