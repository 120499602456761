.video-layer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  overflow: hidden;

  .video-screens {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 25%;
    min-width: 65px;
    border-right: 1px solid #2d2d2c;
    height: 100%;

    .video-screen {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 35px;

      &:first-child {
        height: 34px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #2d2d2c;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .video-layer-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 75%;
    min-width: 120px;

    .video-layer-header {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      padding: 10px;

      .video-layer-icon {
        width: 15px;
        height: 15px;
      }

      .video-layer-file-name,
      .video-duration {
        font-size: 10px;
        padding-left: 10px;
        line-height: 11px;
        color: #666666;
      }
    }
    .video-layer-description {
      color: #222222;
      font-size: 14px;
      font-weight: bold;
      padding: 0 10px;
    }
  }
}
