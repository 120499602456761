.header-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #848484;
  margin-bottom: 15px;

  .header-row-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;

    .timeline-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 20px;
      border-left: 1px solid #848484;
      border-right: 1px solid #848484;
      margin: 1px;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        margin-left: 10px;
        font-weight: bold;
        color: rgba(34, 34, 34, 255);
        font-size: 16px;
      }
    }
  }

  .component-name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;

    span {
      color: rgba(198, 119, 13, 255);
    }
  }
}
