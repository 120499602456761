.media-assets-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(60% - 40px);
  margin: 40px;
  border: 1px solid #929292;
  background-color: #ffffff;
  padding: 0;

  .media-assets-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    .media-assets-title {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: bold;
        font-size: 14px;
        padding: 10px 15px;
      }
    }
    .back-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #efefef;
      width: 50px;
      padding: 12px;
      border: none;

      &:hover {
        cursor: pointer;
        background-color: #c2bdbd;
      }
      .back-button-icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .media-folders {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;

    .assets-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      min-height: 200px;
      background-color: #fafafa;
      border: 1px solid #e2e2e2;
      padding: 5px 10px;

      .video {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 130px;
        margin: 5px;
        overflow: hidden;
        border-radius: 5px;
        border: 3px solid #666666;
        video {
          width: 100%;
        }

        span {
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          padding: 5px;
        }
      }
      .media-assets-groups {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 120px;
        height: 150px;
        margin: 5px;
        padding: 5px 10px;
        overflow: hidden;
        border-radius: 10px;
        //border: 3px solid #666666;

        img {
          width: 100px;
          height: 100px;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }

        span {
          font-size: 12px;
        }
      }

      .no-media-text {
        font-size: 12px;
        font-weight: 400;
        padding: 5px;
      }
    }
  }
}
