// SignIn.scss
.sign-in-container {
  min-width: 350px;
  margin: 0 auto;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .sign-in-header-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #848484;

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1px 30px;
      border-left: 1px solid #848484;
      border-right: 1px solid #848484;
      padding: 10px 15px;

      img {
        width: 24px;
        height: 24px;
      }
      span {
        padding-left: 5px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    flex-direction: column;
    position: relative;

    h4 {
      text-align: center;
      color: #333;
      font-size: 24px;
      margin-bottom: 5px;
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        margin-bottom: 8px;
        color: #555;
        font-size: 14px;
      }

      input {
        padding: 12px;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #646567;
        }
      }

      button {
        padding: 14px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
    .error-message {
      color: red;
      font-size: 14px;
      transition: opacity 1s ease;
      position: absolute;
      bottom: 10px;
    }
  }
}
